var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t('employees_list.employees_list')))])]},proxy:true},{key:"headerAction",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","to":{ name: 'employee.add' }}},[_vm._v(_vm._s(_vm.$t('employees_list.add_employee')))])]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticStyle:{"width":"100%"}},[_c('v-text-field',{attrs:{"append-icon":"ri-search-line","label":_vm.$t('employees_list.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rowData,"search":_vm.search,"footer-props":{
            prevIcon: 'ri-arrow-left-line',
            nextIcon: 'ri-arrow-right-line'
          }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
          var item = ref.item;
return [_c('img',{staticClass:"rounded mr-3",staticStyle:{"object-fit":"cover","width":"50px","height":"50px"},attrs:{"src":item.logo || _vm.profile}}),_vm._v(_vm._s(item.bookingsValue)+" "+_vm._s(item.name)+" ")]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'employee.edit', params: { id: item.token }}}},[_c('button',{staticClass:"btn btn-default text-success",attrs:{"type":"button"}},[_c('i',{staticClass:"ri-pencil-line"}),_vm._v(" "+_vm._s(_vm.$t('employees_list.edit'))+" ")])]),_c('button',{staticClass:"btn btn-default text-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.removeData(item)}}},[_c('i',{staticClass:"ri-delete-bin-line"}),_vm._v(" "+_vm._s(_vm.$t('employees_list.delete'))+" ")])]}}])})]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }