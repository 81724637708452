<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('employees_list.employees_list') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" :to="{ name: 'employee.add' }">{{ $t('employees_list.add_employee') }}</b-button>
          </template>
          <template v-slot:body>
            <div style="width: 100%">
              <v-text-field
                v-model="search"
                append-icon="ri-search-line"
                :label="$t('employees_list.search')"
                single-line
                hide-details
              ></v-text-field>
            </div>
            <v-data-table
              :headers="headers"
              :items="rowData"
              :search="search"
              :footer-props="{
              prevIcon: 'ri-arrow-left-line',
              nextIcon: 'ri-arrow-right-line'
            }"
            >
              <template v-slot:item.name="{ item }">
                <img :src="item.logo || profile" style="object-fit: cover; width:50px; height:50px;" class="rounded mr-3"/>{{ item.bookingsValue }}
                {{ item.name }}
              </template>
              <template v-slot:item.actions="{ item }">
                <router-link :to="{ name: 'employee.edit', params: { id: item.token }}">
                  <button type="button" class="btn btn-default text-success">
                    <i class="ri-pencil-line"></i> {{ $t('employees_list.edit') }}
                  </button>
                </router-link>
                <button type="button" class="btn btn-default text-danger" @click="removeData(item)">
                  <i class="ri-delete-bin-line"></i> {{ $t('employees_list.delete') }}
                </button>
              </template>
            </v-data-table>

          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  name: 'UserList',
  mounted () {
    core.index()
    this.getEmployees()
  },
  data () {
    return {
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || '',
      columnDefs: null,
      rowData: [],
      modules: AllCommunityModules,
      headers: [
        { text: this.$t('employees_list.name'), align: 'start', sortable: true, value: 'name' },
        { text: this.$t('employees_list.email'), value: 'email' },
        { text: this.$t('employees_list.phone'), value: 'phone' },
        { text: this.$t('employees_list.actions'), value: 'actions' }
      ],
      search: ''
    }
  },
  beforeMount () {
  },
  methods: {
    getEmployees () {
      const dataPost = {
        company: this.companyToken
      }
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_EMPLOYEES, dataPost)
        .then((response) => {
          if (response.data.status === 'success') {
            this.rowData = response.data.employees
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    removeData (item) {
      const theToken = item.token
      if (item.admin === '1') {
        alert(this.$t('customer_.cannot_delete_admin'))
        return
      }
      this.$bvModal.msgBoxConfirm(this.$t('customer_.delete_employee'))
        .then(value => {
          this.boxOne = value
          if (value === true) {
            const formData = {
              employee: theToken
            }
            Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
            Axios.post(API.API_DELETE_EMPLOYEE, formData)
              .then((response) => {
                if (response.data.status === 'success') {
                  core.showSnackbar('success', 'User has been removed successfully!')
                  this.$router.go()
                } else if (response.data.status === 'fail') {
                  core.showSnackbar('success', 'There was a problem!')
                }
              })
              .catch((error) => {
                console.error(error.response)
                if (error.response.status === 401) {
                  this.$router.push({ name: 'auth1.login' })
                }
              })
          }
        })
    }
  }
}
</script>
